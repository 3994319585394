import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'field', 'submit']
  static values = {
    // isTarget: {type: Boolean, default: true},
    // updateUrl: String
  }
  connect() {

    // console.log("hello inplace_edit_controller");

    var theThis = this;

    if(theThis.hasFormTarget){

      // console.log("form is there", theThis.formTarget);
    }

    if(theThis.hasFieldTarget){
      let value = theThis.fieldTarget.value;
      // console.log("Field is there", theThis.fieldTarget, value);

      theThis.fieldTarget.addEventListener("keydown", function(event){
        var key = event.which || event.keyCode;
        // console.log("key ! '" + key + "'");

        if(theThis.hasSubmitTarget){
          // console.log("Submit is there", theThis.submitTarget);
          theThis.submitTarget.disabled = false;

        }

        if(theThis.hasFormTarget){
          setTimeout( function() {
            let form = theThis.formTarget;
            fetch(form.action, {
                method: form.method,
                body: new FormData(form),
            });
            //theThis.formTarget.submit();
          },100);
        }
      });
    }





  }

  disconnect(){
    // console.log("Disconnect");
  }
}
