import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'field']
  static values = {
    isTarget: {type: Boolean, default: true},
    updateUrl: String
  }
  connect() {
    var theThis = this;
    theThis.childrenCount = theThis.element.children.length;
    var config = { attributes: true, childList: true, subtree: true };
    theThis.width = "width: " + theThis.element.offsetWidth + "px";
    var callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if(mutation.type == "childList" || mutation.target != theThis.element){
          var hasPlaceholder = false;
          for(var i = 0; i < theThis.element.children.length; i++){
            if(theThis.element.children[i].classList.contains("drop-placeholder")){
              hasPlaceholder = true;
            }
          }
          if(hasPlaceholder){
            theThis.element.classList.add("hovered-category");
            theThis.element.setAttribute('style', theThis.width);
          }
          else{
            theThis.element.classList.remove("hovered-category");
            theThis.element.style.width = "fit-content"
          }
        }
      }
    };
    this.observer = new MutationObserver(callback);
    this.observer.observe(this.element, config);
    var sortable = new Sortable(this.element, {
        group: {name: "all", put: this.isTargetValue},
        ghostClass: "drop-placeholder",
        chosenClass: "chosen-placeholder",
        dragClass: "drag-placeholder",
        onAdd: function(evt){
          let newValue = evt.item.getAttribute("data-card-id")
          if(theThis.hasFieldTarget){
            let values = theThis.fieldTarget.value.split(",")
            if(values[0] == ""){
              values = []
            }
            if(!values.includes(newValue)){
              values.push(newValue)
              theThis.fieldTarget.value = values.join(",")
              theThis.formTarget.submit()
            }
          }
          else{
            window.location.href = theThis.formTarget.getAttribute("href") + "?card_id=" + newValue;
          }
        },
        onUpdate: function (evt) {
          if(!theThis.isTargetValue && ($(theThis.element).children(".item").length - 1 == evt.oldDraggableIndex || $(theThis.element).children(".item").length - 1 == evt.newDraggableIndex)){
            $.ajax(theThis.updateUrlValue, {});
          }
        },
    	}
    );
  }

  disconnect(){
    this.observer.disconnect();
  }
}
